.ProfilePicture {
  text-align: center;

  .profs {
    line-height: 24px;
    height: 24px;
    width: 24px;
    border-radius: 10px;
    display: inline-block;
    background-size: contain;
    // background-image: url("imgs/annonymus.svg");
    background-image: url("imgs/annonymus.jpg");
    background-repeat: no-repeat;
    font-size: 12px;
    text-transform: uppercase;

    &.bigger {
      height: 36px;
      width: 36px;
      line-height: 36px;
      font-weight: bold;
    }

    &.bigger-x {
      height: 46px;
      width: 46px;
      line-height: 46px;
      font-weight: bold;
    }
  }
}
