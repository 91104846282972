.SMFSettings {
  .numberInput {
    height: 35.5px;
  }
}

.SMFSettingsBPLists {
  .action {
    width: 900px;
  }

  .value {
    width: 100px;
  }

  .group {
    width: 300px;
  }
}
