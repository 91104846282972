/* stylelint-disable */

// .ReactTable.-striped {
//   .rt-tr.-odd {
//     .ant-input {
//       color: black;
//     }
//   }
.ant-menu-item {
  .COLORRESET {
    color: lighten(#000, 10%);
  }
}

[class^="COLOR"] {
  &.box {
    width: 150px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// .label-colors {
.COLORRESET {
  color: white;
}

$colors: 1 #1976d2 #fff #fff,
  2 #1e88e5 #fff #fff,
  3 #2196f3 #fff #fff,
  4 #42a5f5 #fff #fff,
  5 #64b5f6 #fff #fff,
  6 #5cffb0 #a3004f #a3004f,
  7 #7dffc0 #82003f #a3004f,
  8 #a2ffd2 #5d002d #a3004f,
  9 #c2ffe2 #3d001d #a3004f,
  10 #ddffef #220010 #a3004f,
  11 #fdfa72 #02058d #a3004f,
  12 #fffd8d #000272 #a3004f,
  13 #fffdaf #000250#a3004f,
  14 #fffec8 #000137 #a3004f,
  15 #fffee0 #00011f#a3004f;

  // old colors, left for ref
  // 6 #ffcae5 #00351a,
  // 6 #ff0081 #fff,
  // 7 #ff48a5 #fff,
  // 8 #ff77bc #fff,
  // 9 #ffaed7 #005128,

.xxx-color {
  font: lso;
}

.req-indicator {
  color: white;
}

@each $it, $bg, $text, $erindicator in $colors {
  .COLOR#{$it},
  [data-color-class="COLOR#{$it}"] {
    background-color: $bg;
    color: $text;

    .req-indicator {
      color: $erindicator;
    }
  }
  .COLOR#{$it} .ant-input {
    background-color: lighten($bg, 10%);

    .req-indicator {
      color: $erindicator;
    }
  }
  .ReactTable.-striped .rt-tr.-odd .COLOR#{$it} .ant-input {
    background-color: darken($bg, 5%);
    color: $text;
  }
  .ReactTable.-striped .rt-tr.-even .COLOR#{$it} .ant-input {
    background-color: $bg;
    color: $text;
  }
}