.ProjectPeopleSettings {
  .name,
  .controls {
    display: inline-block;
    margin-left: 10px;
  }

  .controls {
    margin-left: 5px;
  }

  .action-btn {
    color: #4a90e2;
    cursor: pointer;
    padding: 0 5px;
    margin-top: 3px;
  }

  .add-new-outer {
    min-height: 200px;
  }

  .add-new {
    margin-left: 15px;
    margin-top: 5px;

    button {
      color: #4a90e2;
      cursor: pointer;
    }
  }

  .cell-role {
    padding-right: 10px;
  }

  .ProjectRoleNewLine {
    padding-top: 10px;
  }
}
