.ClientSelector {
  background: white;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-radius: 4px;
  outline: none;
  height: 34px;

  .settings-suffix {
    margin-left: 5px;
    margin-right: 5px;
  }

  .selector {
    width: 280px;

    .ant-select-selection {
      border: 0;
    }
  }

  .anticon {
    &.disabled-false {
      &:hover {
        color: blue;
        transition: 0.3s;
      }
    }

    &.disabled-true {
      color: gray;

      &:hover {
        cursor: default;
        transition: 0.3s;
      }
    }
  }
}

.client-selector-settings {
  &:hover {
    color: red;
    transition: 0.3s;
  }
}
