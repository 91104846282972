.Desktop {
  background: initial;
  padding: 15px;

  .guts {
    background-color: white;
  }

  .room {
    margin: 20px;
    padding: 20px;
    border: 1px dashed gray;
  }
}

.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
