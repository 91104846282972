.LineChart {
  .line {
    fill: none;
    stroke: #1894dd;
    stroke-width: 2px;
  }

  .dot {
    fill: #1894dd;
    stroke: #1894dd;
  }

  .tooltip {
    position: absolute;
    text-align: center;
    width: 30px;
    height: 30px;
    padding: 2px;
    font: 12px sans-serif;
    background: #555;
    color: #fff;
    border: 0;
    border-radius: 15px;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
