.MetaList {
  .someLong {
    width: 600px;
  }

  .saveBtnFix {
    > div {
      display: inline-flex;
    }
  }
}

.MetasEditor {
  .format-icon {
    margin-top: 2px;
    margin-left: 3px;
  }
}
