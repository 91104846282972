.BPJoined {
  .ant-table-thead {
    .D1title {
      background: #fafafa;
    }

    .D2title {
      background: #f3eeee;
    }

    .D3title {
      background: #e0dcdc;
    }
  }

  .view-level-name {
    color: darkgray;

    &.l1 {
      font-size: 11px;
    }

    &.l2 {
      font-size: 10px;
    }

    &.l3 {
      font-size: 10px;
    }
  }
}

.BPJoinedControls {
  .actions-row {
    padding-left: 0;

    .names.toggles {
      margin-left: 0;
    }
  }
}

.BPJoinedNav {
  .anticon.anticon-edit.nav-edit {
    padding-left: 5px;
    margin-right: 1px;
  }

  .ant-menu-horizontal {
    line-height: 30px;

    .ant-menu-item {
      padding-right: 10px;
      padding-left: 10px;
      text-transform: capitalize;
    }

    & > .ant-menu-item-selected {
      border-bottom: 2px solid #8d67ad;
      color: #fafafa;
      background: #8d67ad;

      &.ant-menu-item-active {
        border-bottom: 2px solid #8d67ad;
        color: #fafafa;
      }
    }

    .ant-menu-item-selected.ant-menu-item-active:hover {
      color: darken(#fafafa, 20%);
    }

    & > .ant-menu-item:hover {
      border-bottom: 2px solid #8d67ad;
      color: #8d67ad;
    }
  }
}
