.AdvertiserView {
  .stdInputs {
    width: 300px;
    margin-right: 8px;
  }

  .shortCode {
    width: 100px;
    margin-right: 10px;
  }

  .notes {
    .ant-form-item-label {
      text-align: center;
      display: block;
    }
  }
}

.AdvertiserView-outer {
  height: 42px;
  padding-bottom: 3px;

  button {
    height: 42px;
  }
}
