.ProjectView {
  color: rgba(85, 85, 85, 100);

  .notifications-row {
    background-color: #3BD78D;
    color: white;
    padding: 15px;
    font-size: 12px;
    margin-top: 15px;

    .new-project {
      position: relative;

      .close {
        position: absolute;
        right: 0;
      }
    }

    img {
      height: 20px;
      padding-right: 5px;
    }
  }
}
