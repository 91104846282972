body.login-screen {
  background-color: whitesmoke;
  background-position: -120px 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.LoginPage {
  margin-top: 15vh;

  h4 {
    color: #424242;
  }

  .subtitle {
    color: #535353;
  }

  label {
    font-weight: bold;
    color: #535353;
  }

  p {
    font-size: 0.8rem;
  }

  .space-top {
    padding-top: 30px;
  }

  .login-btn {
    background-color: rgb(74, 144, 226);

    &:hover {
      background: rgba(89, 141, 225, 0.9);
    }
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 1px solid #ccc;
    border-top-color: #07d;
    animation: spinner 0.6s linear infinite;
  }
}
