.ClientsPage {
  .menu-row {
    background: white;

    .top-bar {
      padding: 0;

      ul.dropdown.menu {
        background: white;

        a {
          padding: 0.7rem 0;

          &.acitve {
            border-bottom: 0.125rem solid #4a90e2;
          }
        }
      }
    }
  }

  .list {
    padding-top: 20px;
    padding-left: 25px;

    .tree {
      .tree-cell {
        border: 1px solid rgba(221, 221, 221, 0.4);
        background-color: white;
        padding: 5px;
      }
    }

    .client-cell {
      border: 1px solid rgba(221, 221, 221, 0.4);
      background-color: white;
      padding: 10px;

      .name {
        font-weight: bold;
        color: #555;
      }
    }
  }

  .create-new-button {
    float: right;
    padding-top: 10px;
    padding-right: 10px;
  }

  .tab-view {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.customer-delete-button {
  padding-right: 10px;
  padding-top: 10px;
}

.save-next-buttons {
  padding-right: 10px;
}

.add-new-button {
  padding-right: 10px;
}
