.BPJoinedControls {
  .actions-row {
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .toggles {
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      margin: 3px;

      .cellx {
        margin: 3px 5px;
      }

      .switches {
        margin-top: 5px;
      }

      .actions {
        margin: 0 10px;
      }

      .expo {
        margin-right: 5px;
      }

      .dx-selector {
        display: flex;
        justify-content: center;

        .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          background: #8d67ad;
          border-color: darken(#8d67ad, 10%);
        }
      }
    }
  }
}
