.NewProject {
  font-size: 13px;

  p {
    font-size: 12px;
  }

  .validation-error {
    background-color: #f6d4d4;
    color: #b23d4b;
    padding: 15px;

    img {
      margin-right: 5px;
      margin-top: -3px;
    }
  }

  .validation-required {
    color: white;
    display: inline-block;
    position: relative;
    background: #d9374c;
    padding: 3px 6px;
    padding-left: 6px;
    margin-left: 16px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 100%;
      top: 50%;
      margin-top: -12px;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-right: 12px solid #d9374c;
      border-bottom: 12px solid transparent;
      border-left: 12px solid transparent;
    }
  }

  .v-center {
    display: flex;
    align-items: center;
  }

  .project-submenu {
    .menu {
      height: 40px;
      font-size: 12px;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      li {
        margin: 0 20px;
        max-width: 50%;

        &:first-child {
          margin-left: 0;
        }
      }

      .new-button {
        background-color: #4a90e2;
        font-size: 12px;
        padding-top: 8px;
        padding-bottom: 8px;

        &:hover {
          background-color: #3172bb;
        }
      }
    }
  }

  .new-project-head {
    h4 {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .mid {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .projectName,
    .projectBudget,
    .docketId,
    .customer,
    .reportingUrl {
      margin-bottom: 5px;
      font-size: 12px;
      padding: 1px 1px 1px 10px;
      height: 41px;
    }

    .ant-cascader-picker-show-search.ant-cascader-picker-focused {
      color: rgba(0, 0, 0, 1);
    }

    .customer {
      width: 100%;
      padding-left: 0;

      .ant-cascader-input {
        height: 43px;
      }

      .ant-cascader-picker-label {
        font-size: 13px;
      }
    }
  }

  .smaller-input,
  .tags-input {
    font-size: 12px;
    margin: 2px;
    padding: 2px;
    height: 24px;
  }

  .add-more {
    margin-top: 10px;
    padding: 4px 0;
    color: rgba(59, 122, 219, 1);
  }
}

.NewProjectSutTitle {
  .sub-title {
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 10px 0;
    padding-top: 10px;
    color: rgba(77, 77, 77, 1);
    font-weight: bold;
    line-height: 17px;
  }

  .description {
    font-size: 12px;
    color: rgba(77, 77, 77, 1);
  }
}

.NewProjectTags {
  .suggested-line {
    display: block;
    padding-top: 5px;
  }

  .tags-input {
    @media screen and (min-width: 40em) {
      width: 50%;
    }
  }

  .grid-vertical-margin-5 {
    margin: 5px 0;
  }

  .select-years {
    &.Select--multi .Select-value {
      background-color: rgba(23, 92, 169, 1);
      color: white;
    }
  }

  .select-platforms {
    &.Select--multi .Select-value {
      background-color: rgba(87, 211, 130, 1);
      color: white;
    }
  }

  .select-scopes {
    &.Select--multi .Select-value {
      background-color: rgba(14, 164, 235, 1);
      color: white;
    }
  }
}

.NewProjectPeople {
  .role,
  .user {
    font-size: 12px;
    margin: 2px;
    height: 34px;
    width: 150px;
  }

  .NewPeopleLine {
    padding-bottom: 3px;
  }
}
// Todo: move to out
.removePeopleLine {
  margin-left: 10px;

  img {
    height: 15px;

    &:hover {
      cursor: pointer;
    }
  }
}

.NewProjectMilestones {
  .Select {
    width: 95%;
  }

  .removeMilestoneLine {
    padding-left: 10px;

    img {
      height: 10px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .DayPickerInput {
    margin: 2px;

    input {
      height: 35px;
      padding-left: 10px;
    }
  }
}

.NewProjectNotes {
  .notes-box {
    height: 100px;
  }
}

.NewProjectFooter {
  .create-button {
    width: 200px;
    font-size: 12px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 0;
    background-color: #4a90e2;

    &:hover {
      background-color: #3172bb;
    }
  }

  .cancel-button {
    padding: 20px 10px;
    font-size: 10px;
  }
}
