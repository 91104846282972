.ProjectPeople {
  padding-bottom: 10px;

  .headline {
    padding: 15px 0;

    h6 {
      display: inline-block;
    }
  }

  .icons {
    display: inline-block;
  }

  .names {
    display: inline-block;
    margin-left: 10px;
  }

  .profs {
    margin-right: 0;
    margin-left: -5px;
    transition: all 0.2s ease;

    &:hover {
      margin-left: 0;
    }
  }
}
