.SingleSetting {
  .action-btn {
    color: #4a90e2;
    cursor: pointer;
    padding: 0 5px;
  }

  .mode-view,
  .mode-edit {
    height: 25px;
    display: flex;
  }

  span {
    display: flex;
    align-items: center;
  }

  input[readonly],
  textarea[readonly] {
    background: #fefefe;
    border: 1px solid #fefefe;
    box-shadow: 0 1px 1px rgba(10, 10, 10, 0.1);
    cursor: text;
  }

  .saved {
    color: #2fd88b;
    line-height: 2;
  }

  .fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 3000ms, opacity 3000ms;
  }

  .fade-in {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 3000ms;
  }
}
