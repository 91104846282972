.ProjectSettingsMainView {
  color: #555;

  .setting-row {
    .name {
      color: #999;
    }

    .value {
      color: #555;
    }
  }

  .settings-menu {
    background: #fff;
    margin-bottom: 20px;

    .menu {
      a {
        padding: 0.7rem 0;
        margin: 1rem 1rem 0 1rem;
        color: rgb(85, 85, 85);

        &:first-child {
          margin: 1rem 1rem 0 0;
        }

        &.active {
          border-bottom: 2px solid rgb(74, 144, 226);
          color: rgb(74, 144, 226);
        }
      }
    }
  }

  .notifications-row {
    background-color: #3BD78D;
    color: white;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 12px;
    margin-top: 15px;

    &.error {
      background-color: #ffae00;
    }

    .new-project {
      position: relative;

      .close {
        position: absolute;
        right: 0;
      }
    }

    img {
      height: 20px;
      padding-right: 5px;
    }
  }
}
