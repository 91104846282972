.ProjectAdvertiserNotes {
  border-top: 1px solid rgba(221, 221, 221, 0.4);

  .headline {
    padding: 15px 0 0 0;

    h6 {
      display: inline-block;
    }
  }

  .line {
    padding: 5px 0;

    a {
      color: #555;
    }
  }
}
