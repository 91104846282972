.ProjectGeneralSettings {
  .setting-row {
    padding-bottom: 10px;
  }

  .gds-img {
    width: 250px;
  }

  .admin-action {
    display: inline-block;
    padding-right: 10px;
  }

  .display-inline {
    display: flex;
    width: 100%;
    padding-right: 10px;
  }

  .ProjectAdminSettingsFashooksDefaults {
    padding: 10px 0;
  }
}
