.Help-link {
  a[href]::after {
    position: absolute; /* Prevent underline of external link image */
    padding-left: 2px; /* Add a little space between text and arrow */
    content: url(../icons/icon-external-link.png);
  }
}

.Help-popover {
  max-width: 500px;
}
