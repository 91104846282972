/* stylelint-disable */

// !!!!!! File is left as reference for future use.

// $colors:
//   1 #a5050e #fff #044323 #fff,
//   2 #fc0d1b #fff #155434 #fff,
//   3 #fc5457 #fff #277656 #fff,
//   4 #fd7c7e #fff #389867 #fff,
//   5 #febabb #000 #4aba99 #000;

$colors: 
  1 #c377e0 #000000 #3cdfff #000000, 
  2 #d08fe8 #000000 #63e5ff #000000,
  3 #d5a8e6 #000000 #8aecff #000000, 
  4 #dfc0eb #000000 #b1f2ff #000000,
  5 #eddbf4 #000000 #d8f9ff #000000;


// @each $it, $bge, $fonte, $bgok, $fontok in $colors {
//   .c#{$it}_vali {
//     &.e {
//       background: $bge !important;
//       color: $fonte !important;

//       input {
//         background: $bge !important;
//         color: $fonte !important;
//       }
//     }

//     &.ok {
//       background: $bgok !important;
//       color: $fontok !important;

//       input {
//         background: $bgok !important;
//         color: $fontok !important;
//       }
//     }
//   }
// }

.c1_vali {
  &.e {
    // border: 1px solid red;
    input {
      border: 1px solid red;

      // background: $bge !important;
      // color: $fonte !important;
    }
  }
}

/* stylelint-enable */

//TODO demo code, rm
// $icons: ("eye": "\f112", "start": "\f12e", "stop": "\f12f");

// @each $name, $glyph in $icons {
//   .icon-#{$name}:before {
//     display: inline-block;
//     font-family: "Icon Font";
//     content: $glyph;
//   }
// }
