.ProjectBlockingMainView,
.ProjectBlockingEmpty {
  .stats {
    padding: 10px 0 20px;

    .title {
      color: rgb(170, 170, 170);
    }

    .value {
      display: block;
      color: #333;
    }
  }

  .files {
    padding-top: 20px;
    padding-bottom: 20px;

    .name {
      color: #404040;
      // white-space: nowrap;
    }

    .by-who {
      margin-left: 5px;
      font-style: italic;
      // white-space: nowrap;
    }
  }

  .buttons-row {
    text-align: right;

    .button {
      font-size: 11px;
      margin-bottom: 0;
      margin-right: 3px;
    }

    .download-button {
      width: 126px;
    }

    .generate-button {
      background-color: #4e92df;
    }
  }

  .empty-header {
    padding-top: 10px;
    justify-content: center;

    .down-img {
      height: 16px;
    }

    .donwload-template {
      margin-bottom: 0;
      font-size: 12px;
    }
  }

  .sheet {
    padding-top: 20px;
  }

  .file-upload-oute {
    p {
      margin: 5px;
      text-align: center;
    }

    .add-empty {
      margin-top: 5px;
      font-size: 11px;
    }

    .file-dnd {
      margin: 0 auto;
      width: 90%;
      border: 1px dashed rgb(102, 102, 102);
      border-radius: 2px;
    }
  }
}
