.BPDevRow {
  background: rgba(0, 0, 0, 0.07);
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 3px;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding-left: 6px;
    padding-right: 6px;

    .controls {
      display: flex;

      .elem {
        margin-left: 5px;
      }

      .elem-btn {
        margin-left: 5px;
      }
    }
  }
}
