.Version {
  height: 40px;
  width: 350px;
  padding-top: 5px;
  text-align: right;
  padding-right: 4px;

  &.tiny-root {
    height: 55px;
  }

  .number {
    right: 10px;
    font-size: 10px;
    color: #969696;
    display: block;

    &.tiny {
      position: initial;
      text-align: left;
    }
  }

  img {
    width: 150px;
  }
}
