.CustomViewsDialog {
  width: 1000px;
}

.CustomViews {
  .view-name {
    padding: 0 5px;
  }

  .nameInput {
    padding: 3px;

    input {
      height: 32px;
    }
  }

  .ant-btn {
    margin-right: 8px;
    height: 36px;

    &:last-child {
      margin-right: 0;
    }

    &.icon-btn {
      width: 36px;
    }
  }

  .footer {
    padding-top: 5px;
  }

  .tabs {
    padding: 15px 0;
  }
}

// .ant-modal-mask {
//   z-index: 2000;
// }

// .ant-modal-wrap {
//   z-index: 3000;
// }

.CustomViewSingleCell {
  display: flex;

  > div:hover {
    cursor: pointer;
  }
}

.CustomViewSelector {
  width: 200px;

  .Select {
    width: 100%;
  }
}

.CustomViewsNewName {
  width: 300px;

  input[type="text"] {
    height: 36px;
  }
}

.CustomViewsNewField {
  input[type="text"] {
    height: 36px;
  }
}

.tabs-footer {
  margin-left: 140px;
  margin-top: 10px;
}
