.ProjectNotes {
  @media screen and (min-width: 40em) {
    padding-right: 20px;
  }

  .icon {
    padding-right: 5px;
  }

  .warn {
    margin-top: -10px;

    &::after {
      content: attr(data-warn);
      color: rgb(165, 138, 138);
      position: absolute;
      bottom: -9px;
      right: 25px;
    }
  }
}
