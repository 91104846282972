.BPFileTags {
  margin-top: 5px;

  .ant-tag {
    margin-bottom: 5px;
  }

  .new {
    background: #fff;
    border-style: dashed;

    &:hover {
      cursor: pointer;
    }
  }

  .tag-input {
    width: 80px;
  }
}
