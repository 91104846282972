.Notifications {
  .outer {
    padding: 10px;
    position: relative;
  }

  .error {
    background-color: #F6D4D4;
    color: #B23D4B;
  }

  .warn {
    background-color: #ffae00;
    color: #624300;
  }

  .info {
    background-color: #3BD78D;
    color: #FFF;
  }

  .close {
    position: absolute;
    right: 0;

    .close-img {
      padding-right: 20px;
    }
  }

  .line {
    line-height: 2.5;
  }

  .subline {
    border-bottom: 1px solid rgba(246, 246, 246, 0.5);
    margin-right: 15px;
    margin-left: 25px;
  }

  img {
    margin-right: 5px;
    margin-top: -3px;
    height: 20px;
  }

  .dismiss-all {
    position: absolute;
    right: 10px;
    bottom: 0;
    font-size: 8px;

    button {
      color: white;
      cursor: pointer;
    }
  }
}
