.RecoverPassword,
.ResetPassword {
  color: #666;

  label {
    color: #666;
  }

  label[for='email'] {
    padding-bottom: 10px;
  }

  h4 {
    color: #4a4a4a;
  }

  .v-grid-height {
    height: 94vh;
  }

  .main-row-padding {
    padding: 2vw 0;
  }

  p {
    font-size: 1rem;
  }

  .login-btn {
    background-color: rgb(74, 144, 226);
    margin-top: 10px;

    &:hover {
      background: rgba(89, 141, 225, 0.9);
    }
  }

  ul.password-rules {
    list-style: none;
    margin-left: 0;
    padding-left: 1.2em;
    text-indent: -1.2em;

    li {
      &::before {
        content: url(./fail.svg);
        display: block;
        float: left;
        width: 1.2em;
        margin-right: 0.3rem;
      }

      &.ok {
        &::before {
          content: url(./ok.svg);
        }
      }
    }
  }

  .dont-match {
    color: red;
  }
}
