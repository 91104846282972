.UserCard {
  display: flex;
  align-items: center;

  span {
    padding: 0 0;
  }

  .uid {
    background: #3e4c5c;
    color: white;
    border-radius: 12px;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
  }
}
