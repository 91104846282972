.MetasEditorDialog {
  background: initial;
}

.MetasEditor {
  width: 1000px;

  textarea {
    caret-color: red;

    &[data-has-error='false'] {
      border: 1px solid #0b6d0b;
    }

    &[data-has-error='true'] {
      border: 1px solid red;
    }
  }

  .cell-label {
    display: flex;

    .level {
      display: flex;
      padding-right: 5px;
    }
  }
}

.MetaFieldList {
  .D1:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .D2:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .D3:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;

      button {
        text-transform: none;
        border: 1px solid #0B3452;
        border-radius: 2px;
        padding: 3px;
        margin: 2px;
        cursor: pointer;

        &:focus {
          outline: 0;
        }
      }
    }
  }
}

// .SupportedFormulasList {}
// outside because popover is rendered outside
.SupportedFormulasListBody {
  width: 600px;
  padding: 20px;

  .cellx {
    padding: 2px;
    margin: 2px;
    display: inline-block;
  }
}

.MetasReorder {
  width: 400px;
  // height: 500px;
  overflow: scroll;
}

.sortabe-outer {
  display: flex;
  width: 100%;

  .drag {
    margin-top: -2px;

    &:hover {
      cursor: move;
    }
  }
}

.sorter {
  width: 100%;
  z-index: 100000;
  // width: 250px;
  // float: left;
  // border: 1px solid rebeccapurple;
}

.ConstantsList {
  div {
    display: inline-block;
    padding: 2px;
    margin: 2px;
  }
}
