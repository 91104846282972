.ProjectViewMetric {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  min-height: 100px;

  .name {
    max-width: 50%;
    position: absolute;
    top: 10px;
    left: 10px;
    align-self: flex-start;
    font-size: 11px;
  }

  .value {
    max-width: 50%;
    font-size: 28px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .direction {
    max-width: 50%;

    img {
      margin-left: 5px;
      height: 12px;
      margin-top: -5px;

      &[alt='up'] {
        animation: wiggle 5s infinite;
        animation-delay: 5s;
      }
    }
  }

  .val-outer,
  .val-inner {
    font-size: 28px;
  }
}

@keyframes wiggle {
  0% {
    transform: translate(0, 2px);
  }

  65% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 2px);
  }
}
