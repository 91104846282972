.ActivityLog {
  padding-top: 15px;

  .line {
    padding: 5px 0;
  }

  .profile-img {
    max-width: 35px;
  }

  .note {
    margin-right: 3px;
    word-wrap: break-word;
  }

  .user {
    margin-right: 3px;
  }

  .align-left {
    display: inline-block;
  }
}
