.ProfileChangePwd {
  max-width: 900px;

  label,
  input {
    display: inline-block;
  }

  label {
    width: 50px;
    text-align: right;
    padding-right: 5px;
  }

  input[type='text'],
  input[type='password'] {
    max-width: 200px;
  }

  .title {
    font-size: 16px;
    padding-bottom: 5px;
  }

  .btn {
    margin-left: 40px;
  }

  .error {
    display: inline-block;
    padding-left: 4px;
  }
}
