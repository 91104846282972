.ProjectStatsMainView {
  background-color: rgb(246, 246, 246);

  .filter-menu {
    background: white;

    .menu {
      background: white;

      a {
        padding: 0.7rem 0;
        margin-right: 1rem;
        color: rgb(85, 85, 85);

        &.active {
          border-bottom: 2px solid rgb(74, 144, 226);
          color: rgb(74, 144, 226);
        }
      }
    }
  }

  .stats-body {
    padding-top: 20px;
    position: relative;

    .hideme {
      position: absolute;
      background-color: rgba(235, 235, 235, 1);
      bottom: 8px;
      left: 8px;
      height: 25px;
      width: 130px;
    }
  }

  .acitivty-row {
    background-color: white;
  }
}
