.MaintenancePage {
  .msg {
    position: absolute;
    top: 10%;
    left: 8%;
    font-variant-caps: all-small-caps;
  }

  .blink {
    animation: blink-animation 1s steps(5, start) infinite;
  }

  .blink2 {
    animation: blink-animation 2s steps(2, start) infinite;
  }

  .blink3 {
    animation: blink-animation 2s steps(7, start) infinite;
  }

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
}
