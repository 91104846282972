.ProjectActivitySide {
  .headline {
    padding: 15px 0;

    h6 {
      display: inline-block;
    }
  }

  &.is-page-true {
    .headline {
      p {
        color: #555;
      }
    }
  }
}
