.MainProjectsLineHeader {
  font-size: 11px;
  color: #999;
  padding-bottom: 5px;

  .view-cell {
    text-align: center;
  }

  .name-cell {
    span {
      margin-left: 30px;
    }
  }

  .ends-cell {
    text-align: center;
  }

  .lu-cell {
    span {
      margin-left: 10px;
    }
  }
}
