.ProfilePage {
  background: initial;
}

.ProfileMenu {
  background: gainsboro;
  border-radius: 2px;
}

.UserProfileView {
  .change-pwd {
    padding-top: 15px;
  }
}
