.ProjectGeneralSettings {
  .setting-row {
    padding-bottom: 10px;
  }

  .gds-img {
    width: 250px;
  }

  .admin-action {
    display: inline-block;
    padding-right: 10px;
  }

  .ds-label {
    margin-top: 2px;
  }

  .ds-switch {
    margin-top: 5px;
    margin-left: 3px;
    margin-right: 15px;
  }

  .display-grid {
    .display-inline {
      padding-bottom: 5px;
    }
  }
}
