.ClientViews {
  .ant-tabs-left-bar {
    min-width: 115px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 740px;
    margin-left: 140px;
  }

  .name-tabs {
    .ant-tabs-extra-content .ant-tabs-new-tab {
      background-color: #8d67ad;
      color: whitesmoke;
      font-size: 20px;
      width: 25px;
      height: 25px;
      border-radius: 5px;
    }
  }

  .btn-dirty {
    color: #fff;
    background-color: #8d67ad;
    border-color: #8d67ad;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  }
}

.ClientViewEditor {
  .header {
    padding: 3px 3px 10px;

    .name {
      padding-right: 5px;
      display: inline-block;
      height: 35px;
      padding-top: 8px;
      margin-left: 115px;
    }
  }
}
