.PeoplePage {
  .menu-row {
    background: white;

    .top-bar {
      padding: 0;

      ul.dropdown.menu {
        background: white;

        a {
          padding: 0.7rem 0;

          &.acitve {
            border-bottom: 0.125rem solid #4a90e2;
          }
        }
      }
    }
  }

  .list {
    padding-top: 20px;

    .user-cell {
      border: 1px solid rgba(221, 221, 221, 0.4);
      background-color: white;
      padding: 10px;
      display: flex;

      .name {
        font-weight: bold;
        color: #555;
      }
    }
  }

  .block-btn {
    width: 67px;
  }

  .ant-btn {
    margin-left: 3px;
  }
}
