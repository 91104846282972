.RoleLine {
  .people-row > .cell {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .action-btn {
    color: #4a90e2;
    cursor: pointer;
    padding: 0 5px;
    line-height: 2;

    &.danger {
      color: red;
    }
  }
}
