.BPPage {
  background: initial;

  .relative {
    position: relative;
    // height: 100%;
  }

  .main-shell {
    background: #fff;

    .sidebar-cell {
      z-index: 100;

      .sidebar-outer {
        background-color: rgba(235, 237, 240, 0.96);
      }
    }
  }

  .BPDevRow {
    margin: 5px 10px 0 25px;
  }
}

.BPNav {
  height: 50px;
  background: linear-gradient(270deg, #00d3ac 0%, #007a98 100%);
  color: #fff;

  .grid-x {
    height: 100%;

    .nav-bar {
      height: 100%;

      .nav-bar-left,
      .nav-bar-right {
        height: 100%;

        .menu {
          display: flex;
          height: 100%;
          align-items: bottom;

          .link {
            margin-right: 15px;

            button,
            a {
              padding: 25px 0 10px 0;
              color: white;

              &.active {
                border-bottom: 3px solid white;
              }

              &:focus {
                outline: none;
              }

              &:hover {
                cursor: pointer;
                transition: all 0.1s linear;
                border-bottom: 3px solid rgba(256, 256, 256, 0.5);
              }
            }
          }
        }
      }

      .nav-bar-right {
        .menu {
          float: right;
        }
      }
    }
  }
}
