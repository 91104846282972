.Sidebar {
  .ant-menu.ant-menu-dark {
    .ant-menu-item-selected {
      background: #8d67ad;

      &.footer-bug-btn {
        background: #3e4c5c;
      }

      &.footer-help-btn {
        background: #3e4c5c;
      }
    }
  }
}
