$marginLeft: 1.5625rem;
$marginRight: 25px;

.MainNavView {
  .projects-header {
    padding-top: 1.25rem;
    padding-left: $marginLeft;
    padding-right: $marginRight;
    background-color: white;
  }

  &.SoonNavView,
  &.PeopleNavView &.ClientsNavView {
    .projects-header {
      padding: 1.25rem $marginLeft;
      background-color: white;
      display: flex;
      align-items: center;
    }
  }

  .top-bar {
    // margin-left: $marginLeft;
    padding: 0 0 0 $marginLeft;
    background-color: white;

    ul {
      background-color: white;

      li {
        font-size: 0.75rem;

        &.disabled {
          color: #d2d0d0;

          a {
            color: #d2d0d0;
            cursor: not-allowed;

            &.active {
              color: #d2d0d0;
              border-bottom: 0.125rem solid #d2d0d0;
            }
          }
        }

        button,
        a {
          color: #555;
          padding: 0.7rem 0.1rem;
          margin-left: 1rem;
          margin-right: 1rem;

          &:first-child {
            margin-left: 0;
          }

          &.active {
            color: #8d67ad;
            border-bottom: 0.125rem solid #8d67ad;
          }
        }
      }
    }
  }

  &.ProjectNavView {
    // for future.
    // opacity: 0.1;
    // transition: opacity 10s;

    // &[data-api-loaded='true'] {
    //   opacity: 1;
    // }

    .docket-code {
      margin-right: $marginRight;
      color: #aaa;
    }

    .docket-code-right {
      @media screen and (max-width: 699px) {
        display: none;
      }
    }

    .docket-code-top {
      @media screen and (min-width: 700px) {
        display: none;
      }
    }
  }

  .top-bar-right {
    margin-right: 25px;
  }

  .project-settings {
    display: flex;
    justify-content: flex-end;

    .ant-input-search {
      .ant-input-suffix {
        right: 24px;
      }
    }
  }
}
