.BPCosting {
  background: initial;

  &[data-saveinprogress="true"] {
    background: #F6F6F6;
  }

  .BPCostingInputs {
    label {
      color: #333;
      font-size: 11px;
      margin-right: 10px;
    }

    input {
      width: 120px;
      height: 25px;
      padding: 4px;
      margin-bottom: 0;
    }
  }
}

.BPShell {
  .BPJoinedNav {
    padding-top: 5px;
  }
}
