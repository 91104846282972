.ClientDefaults {
  border: 1px solid gray;

  .save-footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.ClientDefaults-lists-ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
