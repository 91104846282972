.FileTypes {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: white;
  font-size: 11px;
  padding: 3px 5px;
  margin-right: 3px;
  width: 40px;
  text-align: center;
  font-weight: bold;
  background-color: rgb(43, 35, 36);

  &.pdf {
    background-color: rgb(219, 53, 73);
  }

  &.xls,
  &.xlsx {
    background-color: rgb(24, 106, 67);
  }
}
