@import "../../_settings";

// Ugly code, but this is not part of this sprint so just moving to looke decent
.Footer {
  width: 100%;
  flex-shrink: 0;

  .all-rights {
    font-size: 0.9rem;
    padding-top: 0.5rem;
    color: rgb(150, 150, 150);
    text-align: center;
  }

  &.is-logged-false {
    @media screen and (min-height: 31.25rem) { //500px
      position: fixed;
      bottom: 0;
    }

    .menu,
    .logo {
      justify-content: flex-start;
    }

    .all-rights {
      text-align: left;
    }
  }

  &.is-logged-true {
    background: #3e4c5c;
    height: $footer-height;
    position: absolute;
    bottom: 0;
    margin-left: 80px;
    width: calc(100% - 80px);
    animation: fadein 0.6s;

    .menu,
    .logo {
      justify-content: center;
    }
  }

  .tnc-links {
    padding-top: 2rem;

    a {
      color: #969696;
      font-size: 0.8rem;
    }
  }

  .version-outer {
    position: relative;

    .Version {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
