.ProjectActivityMainView {
  background: white;

  .filter-menu {
    .menu {
      background: white;

      a {
        padding: 0.7rem 0;
        margin-right: 1rem;
        color: rgb(85, 85, 85);

        &.active {
          border-bottom: 2px solid rgb(74, 144, 226);
          color: rgb(74, 144, 226);
        }
      }
    }
  }

  .acitivty-row {
    background-color: rgb(246, 246, 246);
  }
}
