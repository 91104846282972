.BPImportMass {
  .text-area {
    height: 3vh;
  }

  .manual-delimiter {
    width: 35px;
  }

  .top-actions {
    display: flex;
    margin: 3px 0;
    justify-content: space-between;

    .detector {
      display: flex;

      .radio,
      .radio-group {
        line-height: 32px;
      }
    }
  }

  .bottom-row {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-content: center;
  }

  .btn-margin {
    margin-right: 8px;
  }

  .note {
    margin-top: 5px;
    margin-right: 8px;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }
}

.BPImportCol {
  .text-area {
    height: 80vh;
  }

  .bottom-row {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-content: center;
  }

  .btn-margin {
    margin-right: 8px;
  }

  .note {
    margin-top: 5px;
    margin-right: 8px;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }
}
