.Registration {
  max-width: 900px;

  .block {
    margin: 15px 5px;
    padding: 5px 5px;

    &[data-invalidblock='true'] {
      border: red 1px solid;
      margin: 5px;
    }
  }

  [data-invalid='true'] {
    input {
      border: red 1px solid;
    }
  }

  .red-star {
    padding: 0 3px;
    color: red;
  }
}

.RegistrationNotes {
  input[type='checkbox'] {
    margin-right: 5px;
  }

  .error-msg {
    color: darkred;
  }

  [data-invalid='true'] {
    border: red 1px solid;
  }
}
