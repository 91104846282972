.ProjectReportsMainView,
.ReportsPage {
  background-color: rgb(246, 246, 246);

  .filter-menu {
    background: white;

    .menu {
      background: white;

      a {
        padding: 0.7rem 0;
        margin-right: 1rem;
        color: rgb(85, 85, 85);

        &.active {
          border-bottom: 2px solid rgb(74, 144, 226);
          color: rgb(74, 144, 226);
        }
      }
    }
  }

  .acitivty-row {
    background-color: white;
  }

  h6 {
    padding-top: 1rem;
  }

  .title-row {
    color: #999;
    padding-top: 10px;

    .name-cell-header {
      padding-left: 38px;
    }

    .date-cell-header {
      justify-content: flex-end;
      display: flex;
      padding-right: 20px;
    }

    .project-cell-header {
      text-align: right;
      padding-right: 10px;
    }

    .by-cell-header {
      text-align: center;
      padding-right: 25px;
    }

    button {
      margin-left: 5px;
    }

    .sort-img {
      height: 9px;
      width: 9px;
      margin-top: -1px;
      cursor: pointer;
    }
  }

  .file-row {
    padding: 1rem;
    border: 1px solid rgba(221, 221, 221, 0.4);

    [type="checkbox"].download-checkbox {
      margin-right: 10px;
      margin-bottom: 0;
    }

    .FileTypes {
      margin-right: 10px;
    }

    .date-cell {
      justify-content: flex-end;
    }

    .project-cell {
      text-align: right;
      justify-content: flex-end;
    }

    .client-cell {
      justify-content: center;
    }
  }

  .bottom-row {
    padding-top: 20px;

    .download-button,
    .upload-button {
      font-size: 11px;
      padding-left: 30px;
      padding-right: 30px;
      width: 125px;
      margin-right: 10px;
      background: #4a90e2;
    }
  }
}
