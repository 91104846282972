.Fasthooks {
  background: initial;

  .custom-drop-down-w100p {
    width: 100%;
  }

  .btn-wrap {
    .btn-place {
      display: flex;
      justify-content: flex-end;
      margin-right: 15px;

      .ant-btn {
        margin-left: 15px;
      }
    }
  }

  .ReactTable.-striped .rt-tr.-odd input {
    background: transparent;
  }

  .ReactTable .rt-td {
    padding: 1px;
  }

  .cell-info {
    padding-top: 7px;
    padding-left: 4px;
  }

  .header-span {
    padding-top: 5px;
    display: inline-block;
  }
}

.FasthookCallbackPanel {
  .ant-tabs-bar {
    margin: 0;
  }

  .ant-tabs.ant-tabs-card {
    .ant-tabs-card-bar {
      .ant-tabs-nav-container {
        height: 30px;
      }

      .ant-tabs-tab-active,
      .ant-tabs-tab {
        height: 30px;
        line-height: 30px;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .ant-form-item-label {
    padding-bottom: 0;

    label {
      font-size: 12px;
    }
  }

  .sep {
    padding-right: 5px;
  }
}

.FasthooksConditions .condition-new-row {
  padding: 5px 10px;
}

.FasthooksSelection {
  .basic-single {
    margin-right: 5px;
    margin-left: 5px;

    .Select-control {
      height: 40px;
    }

    .Select-value {
      line-height: 15px;
    }
  }

  .NameInput {
    margin-top: 5px;
    padding-right: 10px;
    margin-left: 5px;
    height: 41px;
    border-radius: 4px;

    input {
      height: 41px;
    }

    .input-group-field {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .save {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .complete-indicator {
    padding-right: 3px;
    padding-top: 3px;
    color: green;
  }
}

.FasthooksPopper {
  width: 400px;
  height: 40px;
}

#fast-popper {
  > div {
    overflow: visible;
  }
}

.FasthooksMaps {
  .custom-drop-down {
    padding-left: 3px;
  }
}

.fast-popper {
  margin-top: -25px;

  .fh-select__menu {
    margin-top: 0;
  }

  .fh-select__control {
    min-height: 30px;
    height: 30px;
    line-height: 24px;
  }

  .fh-select__value-container {
    padding: 0;
  }

  .fh-select__indicator {
    padding: 0;
    margin-top: -5px;
  }

  .fh-select__indicator-separator {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .fh-select__single-value {
    top: 40%;
  }
}

.FasthooksBody {
  padding-top: 10px;

  .push-top {
    padding-top: 5px;
  }

  label {
    span {
      font-size: 12px;
      display: inline;
    }
  }

  &.padding-lr-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .grid-x > .cell {
    padding-left: 4px;
    padding-right: 4px;
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
  }

  textarea[name="body"] {
    min-height: 100px;
  }
}

.FasthookLogs {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 3px;
  }

  .body-select {
    user-select: text;
    display: flex;

    div {
      display: flex;
    }
  }

  .expanded-record {
    .grid-margin-x-custom > .cell {
      padding: 0 2px;
    }

    label {
      font-size: 11px;

      .anticon {
        color: lightgray;
      }
    }
  }

  pre {
    background: rgba(128, 128, 128, 0.17);
    margin-bottom: 3px;
    padding-left: 2px;
  }

  .body-cell,
  .title-cell {
    margin: 0 2px;

    &::after {
      content: "}";
      color: lightgray;
      padding: 0 2px;
    }

    &::before {
      content: "{";
      color: lightgray;
      padding: 0 2px;
    }

    .anticon {
      color: lightgray;
    }
  }

  .ant-table-title {
    padding: 3px 5px;

    .table-header {
      display: flex;
      justify-content: space-between;

      .right {
        display: flex;
        justify-content: flex-end;
      }

      .left {
        display: flex;
        justify-content: flex-start;

        .project-info {
          padding-left: 20px;
          margin-right: 5px;
          font-weight: bold;

          span {
            &.docket {
              color: #aaa;
            }
          }
        }
      }

      .elem {
        margin-top: 5px;
        margin-right: 5px;
      }

      .elem-sync {
        margin-top: 8px;
        margin-left: 5px;
      }

      .elem-no-top {
        margin-right: 5px;
      }
    }
  }
}
