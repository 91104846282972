.ProjectMilestonesSettings {
  .next,
  .comming-up,
  .completed {
    padding-bottom: 20px;
  }

  .sub-title {
    padding-bottom: 5px;
  }

  [type="checkbox"] {
    margin-right: 5px;
  }

  .name,
  .controls {
    display: inline-block;
    margin-left: 10px;
  }

  .controls {
    margin-left: 5px;
  }

  .action-btn {
    color: #4a90e2;
    cursor: pointer;
    padding: 0 5px;
  }

  .add-new {
    margin-left: 15px;
    margin-top: 5px;

    button {
      color: #4a90e2;
      cursor: pointer;
    }
  }

  .MilestonSetingsNewLine {
    padding: 5px 0;

    .DayPickerInput > input {
      height: 24px;
      border: 1px solid rgb(204, 204, 204);
      border-radius: 5px;
      padding-left: 5px;
      width: 100px;
    }

    .Select {
      display: inline-block;
      width: 150px;
    }

    .Select-control,
    .Select-placeholder,
    .Select-input {
      height: 20px;
      line-height: 20px;
    }

    .Select-input > input {
      padding: 0 0 0;
    }

    .Select--single > .Select-control .Select-value {
      line-height: 20px;
    }
  }
}
