.BPPopper {
  width: 200px;
  // this height is important that much, because #bptable-popper will be bigger and be on top of this.
  height: 40px;

  .btn-pos {
    bottom: 0;
  }
}

/* stylelint-disable */
.BPTable {
  &[data-level="dynamic1"] {
    .BPTableActions {
      .names {
        margin-left: 450px;
      }
    }
  }

  &[data-level="dynamic2"] {
    .BPTableActions {
      .names {
        margin-left: 426px;
      }
    }
  }

  &[data-level="dynamic3"] {
    .BPTableActions {
      .names {
        margin-left: 404px;
      }
    }
  }
}
/* stylelint-enable */

.BPTableActions {
  .actions-row {
    height: 33px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .names {
      margin-top: 5px;
    }

    .toggles {
      display: flex;
      flex-direction: row;

      .cellx {
        margin: 3px 5px;
      }

      .expo {
        margin-left: 6px;
      }
    }

    .left {
      display: flex;
      justify-content: space-between;

      .btn {
        margin-right: 5px;
      }

      .actions-outer {
        margin-left: 45px;
        // width: 163px;
      }
    }
  }

  .tool-all {
    color: white;
    background-color: #9c4ae0;
  }
}

#bptable-popper {
  > div {
    overflow: visible;
  }
}

.ReactTable .rt-thead.-filters select {
  padding: 0 0 0 3px;
}

.ReactTable .-pagination select {
  padding: 1px 6px;
}

.ReactTable.-striped .rt-tr.-odd {
  // background: #dce6f1;
  background: #c1bfbf;

  .ant-input {
    // background: rgb(220, 230, 241);
    background: #c1bfbf;

    &.formula-true {
      // background: rgb(206, 213, 220);
      background: #c1bfba;
    }
  }
}

.ReactTable {
  .formula-true {
    background: #f1eded;
  }

  .rt-thead .rt-resizable-header:last-child {
    overflow: visible;
  }

  .bptable-th-custom-controls {
    select {
      color: black;
    }
  }

  .admin-hooks-drop {
    display: inline;

    .ant-btn-default {
      padding: 0;
    }

    .thunder-icon {
      padding: 6px;
    }
  }

  .thunder-icon {
    color: red;
    border: 1px dashed red;
    border-radius: 3px;

    &.hooks-state-1 {
      background: red;
      color: white;
    }

    &.hooks-state-2 {
      color: gray;
      border: 1px gray dashed;
    }

    &.hooks-state0 {
      background: yellow;
    }

    &.hooks-state1 {
      background: #fff;
    }
  }
}

.custom-buttons-prefix,
.actions-row {
  .btn-delete {
    background: #9c4ae0;
    border: #9c4ae0;
  }
}

// .ReactTable .rt-thead .rt-tr {
//   background: #40a9ffb3;
//   font-weight: bold;
// }

.context_menu_class1,
.context_menu_class2,
.context_menu_class3 {
  cursor: context-menu;
  display: inline;
}

.ReactTable.-highlight .-tr-head.-header {
  background: #ab93bf !important;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: #ab93bf !important;
}
/* stylelint-disable */
.BPTable {
  .col-head {
    .sums {
      img {
        height: 12px;
        margin-top: -2px;
        padding-right: 2px;
      }
    }
  }

  &[data-level="dynamic1"] {
    .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
      background: #615f5f !important;
    }

    .rt-thead .rt-tr {
      &:hover {
        background: #615f5f !important;
      }
    }
  }

  &[data-level="dynamic2"] {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    background: #d3d3d338;

    .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
      background: #615f5f !important;
    }

    .rt-thead .rt-tr {
      // background: #40a9ff6e;
      background: #615f5f;
      font-weight: bold;

      &:hover {
        // background: #40a9ff6e !important;
        background: #615f5f !important;
      }
    }
  }

  &[data-level="dynamic3"] {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    background: #d3d3d338;

    .rt-thead .rt-tr {
      // background: #40a9ff42;
      background: #615f5f;
      font-weight: bold;

      &:hover {
        // background: #40a9ff42 !important;
        background: #615f5f !important;
      }
    }
  }

  .table-wrapper {
    // width: 2000px;
    // height: 800px;
  }

  .rt-thead .rt-tr {
    // background: #40a9ffb3;
    background: #615f5f;
    font-weight: bold;
  }

  .rt-thead.-filters {
    .icon {
      display: none;
    }
  }

  .table-1 {
    .rt-tfoot {
      height: 25px;
    }
  }

  .ant-input-number {
    width: 100%;
  }

  .-padRow {
    cursor: copy;
  }

  .checkbox-empty {
    span {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .better-filter {
    .ant-input {
      padding: 0 0;
      height: 23px;
    }
  }

  .ReactTable {
    .rt-td {
      padding: 1px 1px;
    }

    .rt-th {
      // color: white;
      position: relative;
    }
    // if we do this, then expansion will not work
    .rt-tbody {
      .rt-tr-group {
        min-height: 36px;
      }
    }

    .tr-th-child-wrap {
      display: inline-grid;
      width: 90%;
    }

    .rt-resizer {
      width: 16px;
      right: -8px;

      &:hover {
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }

  .bptable-th-custom-controls {
    svg:hover path {
      fill: wheat;
      transition: all ease 0.3s;
    }

    svg path {
      fill: white;
      transition: all ease 0.3s;
    }
  }

  .bptable-th-custom {
    display: inline;
    top: 3px;
    right: 12px;
    position: absolute;
    background: whitesmoke;
  }

  .custom-drop-down {
    // This used to be icon to indicate drop-down, not needed anymore
    // Left for reference.
    // &::after {
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   background-image: url(../../../shared/icons/shevron-down.svg);
    //   background-repeat: no-repeat;
    //   background-position: 94% center;
    //   background-size: 20px 20px;
    // }

    input[readonly] {
      cursor: pointer;
    }
  }

  .custom-file,
  .custom-bool {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[readonly] {
    cursor: pointer;
  }

  .ro-false {
    border: 1px dashed rosybrown;
  }

  .addEmptyLine-wrapper {
    display: flex;
    justify-content: center;
  }

  #save-footer {
    width: 56px;
    position: absolute;
  }

  .custom-footer {
    height: 60px;

    .custom-footer-elements {
      height: 60px;
      background: rgb(247, 246, 247);
    }

    .button-wrapper {
      padding: 10px;
    }
  }
}
/* stylelint-enable */

/// Workaround, if we don't have this styles for input is missing,
/// Because it's overridden by foundation, because somehow it's compiled first.
/// File from ./node_modules/antd/lib/input/style/index.css
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */

.ant-input {
  font-variant: tabular-nums;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-input:focus {
  border-color: #40a9ff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px !important;
}
.ant-input-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-input-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
  min-height: 32px;
}
.ant-input-lg {
  padding: 6px 11px;
  height: 40px;
  font-size: 16px;
}
.ant-input-sm {
  padding: 1px 7px;
  height: 24px;
}
.ant-input-group {
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: table;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}
.ant-input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}
.ant-input-group > [class*="col-"] {
  padding-right: 8px;
}
.ant-input-group > [class*="col-"]:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group-addon {
  padding: 0 11px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select .ant-select-selection {
  background-color: inherit;
  margin: -1px;
  border: 1px solid transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #1890ff;
}
.ant-input-group-addon > i:only-child:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6px 11px;
  height: 40px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 1px 7px;
  height: 24px;
}
.ant-input-group-lg .ant-select-selection--single {
  height: 40px;
}
.ant-input-group-sm .ant-select-selection--single {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  width: 100%;
  float: left;
}
.ant-input-group.ant-input-group-compact {
  display: block;
  zoom: 1;
}
.ant-input-group.ant-input-group-compact:before,
.ant-input-group.ant-input-group-compact:after {
  content: "";
  display: table;
}
.ant-input-group.ant-input-group-compact:after {
  clear: both;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact
  > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact
  > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact
  > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  border-radius: 0;
  vertical-align: top;
  float: none;
  display: inline-block;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  border-right-width: 1px;
  margin-right: -1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-mention-wrapper
  .ant-mention-editor,
.ant-input-group.ant-input-group-compact
  > .ant-time-picker
  .ant-time-picker-input {
  border-radius: 0;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact
  > .ant-select
  > .ant-select-selection:hover,
.ant-input-group.ant-input-group-compact
  > .ant-calendar-picker
  .ant-input:hover,
.ant-input-group.ant-input-group-compact
  > .ant-select-auto-complete
  .ant-input:hover,
.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker
  .ant-input:hover,
.ant-input-group.ant-input-group-compact
  > .ant-mention-wrapper
  .ant-mention-editor:hover,
.ant-input-group.ant-input-group-compact
  > .ant-time-picker
  .ant-time-picker-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact
  > .ant-select
  > .ant-select-selection:focus,
.ant-input-group.ant-input-group-compact
  > .ant-calendar-picker
  .ant-input:focus,
.ant-input-group.ant-input-group-compact
  > .ant-select-auto-complete
  .ant-input:focus,
.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker
  .ant-input:focus,
.ant-input-group.ant-input-group-compact
  > .ant-mention-wrapper
  .ant-mention-editor:focus,
.ant-input-group.ant-input-group-compact
  > .ant-time-picker
  .ant-time-picker-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact
  > .ant-select:first-child
  > .ant-select-selection,
.ant-input-group.ant-input-group-compact
  > .ant-calendar-picker:first-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-select-auto-complete:first-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker:first-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-mention-wrapper:first-child
  .ant-mention-editor,
.ant-input-group.ant-input-group-compact
  > .ant-time-picker:first-child
  .ant-time-picker-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact
  > .ant-select:last-child
  > .ant-select-selection,
.ant-input-group.ant-input-group-compact
  > .ant-calendar-picker:last-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-select-auto-complete:last-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker:last-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker-focused:last-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-mention-wrapper:last-child
  .ant-mention-editor,
.ant-input-group.ant-input-group-compact
  > .ant-time-picker:last-child
  .ant-time-picker-input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact
  > .ant-select-auto-complete
  .ant-input {
  vertical-align: top;
}
.ant-input-group-wrapper {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.ant-input-affix-wrapper {
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper .ant-input {
  position: relative;
}
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 0;
  color: rgba(0, 0, 0, 0.65);
}
.ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  line-height: 1.5;
}
.ant-input-affix-wrapper .ant-input-prefix {
  left: 12px;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
}
.ant-input-affix-wrapper .ant-input {
  min-height: 100%;
}
.ant-input-password-icon {
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input-password-icon:hover {
  color: #333;
}
.ant-input-search-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input-search-icon:hover {
  color: rgba(0, 0, 0, 0.8);
}
.ant-input-search .ant-input-group-addon {
  border: 0;
  padding: 0;
}
.ant-input-search .ant-input-group-addon .ant-input-search-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}

.color-menu {
  span {
    display: inline-block;
    width: 150px;
    font-weight: bold;
    text-align: center;
  }
}

// @import "./BPColors.scss";
