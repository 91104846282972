$height: 30px;

.SystemNotifications {
  height: 0;

  &.show {
    display: block;

    &[data-count="0"] {
      height: $height;
    }

    &[data-count="1"] {
      height: $height * 2;
    }

    &[data-count="2"] {
      height: $height * 3;
    }

    &[data-count="3"] {
      height: $height * 4;
    }

    &[data-count="4"] {
      height: $height * 5;
    }
  }

  .messages {
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
    background: #fde073;
    text-align: center;
    line-height: 2.5;
    overflow: hidden;
    box-shadow: 0 0 5px black;
    display: none;
    height: $height;

    .date {
      color: gray;
    }

    &.show {
      display: block;

      &[data-count="0"] {
        height: $height;
      }

      &[data-count="1"] {
        top: $height;
      }

      &[data-count="2"] {
        top: $height * 2;
      }

      &[data-count="3"] {
        top: $height * 3;
      }

      &[data-count="4"] {
        top: $height * 4;
      }
    }

    .close-img {
      height: 20px;
      width: 20px;
      margin-top: -4px;
      padding-left: 4px;
    }
  }
}
