.MainProjectsView {
  background-color: #F6F6F6;

  .search-line {
    padding: 15px 25px;
  }

  .project-new {
    padding-left: 54px;
    padding-top: 10px;
    padding-bottom: 25px;

    a {
      color: rgb(153, 153, 153);
    }
  }

  .ProjectSearch {
    padding-right: 10px;

    .input-group-field {
      border-right: 0;

      &:focus {
        border: 1px solid #cacaca;
      }
    }

    .input-group-label {
      font-size: 12px;
      border-left: 0;
      background-color: white;
      padding: 0 0.5rem;
      cursor: pointer;
    }

    .input-group {
      height: 34px;
    }
  }

  .not-found {
    text-align: center;
    padding-top: 10px;
    color: #999;
  }

  .project-settings {
    display: flex;
    justify-content: flex-end;
  }
}
