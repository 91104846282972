.ProjectViewMetricHolder {
  .metrics {
    padding-top: 5px;
    background: linear-gradient(90deg, #0063AD 0%, #002C79 100%);
  }

  .metric-bordered-right {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 15px;
      width: 1px;
      height: 70px;
      background: rgba(221, 221, 221, 0.4);
    }
  }

  .metric-bordered-left {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 15px;
      width: 1px;
      height: 70px;
      background: rgba(221, 221, 221, 0.4);
    }
  }

  .metric-bottom-line {
    width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid rgba(221, 221, 221, 0.4);
  }

  .border-right {
    border-right: 1px solid rgba(221, 221, 221, 0.4);
  }
}
