@import url("https://fonts.googleapis.com/css?family=Open+Sans|Raleway|Roboto:300,400,500,700,900|Material+Icons");

$topbar-background: rgb(45, 55, 67);
$topbar-background-grad: rgba(45, 55, 67, 1);
$dropdownmenu-min-width: 75px;
$dropdownmenu-arrow-color: #fff;

$header-font-weight: bold;
$header-font-family: "Roboto", sans-serif;
$body-font-family: "Roboto", sans-serif;

$body-font-size: 12px;

$active-menu-bottom-border: rgb(89, 141, 225);

$footerHeight: 3.75rem;

$form-spacing: rem-calc(12px);
$input-font-size: rem-calc(12px);
$input-padding: rem-calc(4px);
$form-spacing:  rem-calc(4px);

$footer-height: 60px;

$bp-sidebar-width: 190px;

$bp-smart-line-height: 65px;
$bp-smart-line-height-no-labels: 40px;

$bp-tactics-line-height: 40px;
$bp-sub-nav-margin-top: 20px;
$bp-sub-nav-margin-bottom: 20px;

$bp-new-tactic-padding-top: 15px;
$bp-new-tactic-padding-bottom: 15px;
$bp-new-tactic-button-height: 20px;
$bp-border-bottom-height: 1px;

$bp-sidebar-offset-top: $bp-tactics-line-height + $bp-sub-nav-margin-top + $bp-sub-nav-margin-bottom + $bp-new-tactic-padding-top + $bp-new-tactic-padding-bottom + $bp-new-tactic-button-height + $bp-border-bottom-height;
