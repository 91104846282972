.AccountMenu2 {
  // margin-left: -5px;
  max-width: 100px;
}

#account-menu {
  .name {
    font-size: 14px;
  }

  .link {
    color: #1779ba;
    font-size: 14px;
  }
}

.AccountMenu {
  margin-left: -5px;

  .user-name {
    color: white;
    font-size: 12px;
    text-align: center;
  }

  .ProfilePicture {
    .profs {
      border-radius: 5px;
    }
  }

  .sub-menu-parent {
    position: relative;
    padding-top: 5px;
    background-color: #3e4c5c;
    border-radius: 3px;

    a {
      padding: 0;
    }
  }

  .sub-menu {
    visibility: hidden; /* hides sub-menu */
    background-color: rgb(223, 223, 223);
    border: 1px solid #657485;
    border-radius: 2px;
    opacity: 0;
    position: absolute;
    top: 25%;
    left: 60px;
    width: 160px;
    transform: translateX(-2em);
    z-index: -1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

    li {
      width: 100%;
    }

    .head {
      border-bottom: 1px solid gray;
      padding-bottom: 5px;
      padding-top: 10px;
    }

    /* This is bad but will be redone, since dosnt' work for mobile */
    .sub-menu-item {
      padding: 0.7rem 1rem !important;
      font-size: 12px;
      padding-top: 0 !important;
      padding-left: 16px !important;
    }
  }

  .sub-menu-parent:focus .sub-menu,
  .sub-menu-parent:focus-within .sub-menu,
  .sub-menu-parent:hover .sub-menu {
    visibility: visible; /* shows sub-menu */
    opacity: 1;
    z-index: 100;
    transform: translateX(0);
    transition-delay: 0s, 0s, 0.3s; /* this removes the transition delay so the menu will be visible while the other styles transition */
  }
}
