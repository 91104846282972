.ProjectMilestones {
  border-top: 1px solid rgba(221, 221, 221, 0.4);
  padding-bottom: 10px;

  .headline {
    padding: 15px 0 0 0;

    h6 {
      display: inline-block;
    }
  }

  .sub-title {
    padding: 10px 0;
  }
}
