$lineHeigh: 52px;
$lineHeighSmall: auto;

.MainProjectsLine {
  @media screen and (max-width: 39.9375em) {
    height: $lineHeighSmall;
  }

  // height: $lineHeigh;
  margin-bottom: 2px;
  background-color: #fff;
  box-shadow: -2px 2px 6px 0 rgba(0, 0, 0, 0.09);

  .fixed-height {
    @media screen and (max-width: 39.9375em) {
      height: $lineHeighSmall;
    }

    height: $lineHeigh;
    display: flex;
    align-items: center;
  }

  .arrow {
    height: 12px;
    margin-top: -2px;
    padding-right: 2px;
  }

  .name-cell {
    display: flex;
    // line-height: $lineHeigh;
    // height: $lineHeigh;

    .link-block {
      // display: inline-block;
      flex: 1;
      display: flex;
      align-items: center;
    }

    .company-name-color {
      background-color: #8d67ad;
      box-shadow: -2px 2px 6px 0 rgba(0, 0, 0, 0.09);
      width: 25px;
      height: $lineHeigh;
      margin-right: 5px;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .name {
        display: inline-block;
      }

      img {
        width: 70%;
        margin-left: 4px;
      }

      &.is-open-true {
        img {
          transform: rotate(90deg);
        }
      }
    }

    .code {
      font-size: 10px;
      padding-left: 5px;
    }
  }

  .timevsbudget-cell {
    text-align: center;
  }

  .cpm-cell {
    text-align: center;
  }

  .spend-cell {
    text-align: center;
  }

  .view-cell {
    text-align: center;
  }

  .ends-cell {
    text-align: center;
  }

  .lu-cell {
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 39.9375em) {
      padding: 10px 0;
    }

    .ProfilePicture {
      height: 24px;
    }

    img {
      padding: 0 5px;
    }
  }

  .chart-line {
    text-align: center;
    background-color: #f6f6f6;
    padding: 10px 0;

    &.is-open-true {
      display: block;
    }

    &.is-open-false {
      display: none;
    }

    .chart-cell {
      border-right: 1px solid #c6c6c6;

      &:last-child {
        border-right: 0;
      }
    }
    // .LineChart:last-child {
    //   border-right: 0;
    // }
  }

  .no-right-margin {
    margin-right: 0;
  }

  .no-left-margin {
    margin-left: 0;
  }

  .ProjectStatus {
    .selector {
      width: 100px;
    }
  }
}
